import React, {useState} from "react";
import {Price, PriceInterval} from "../../dataModel";
import {XCircleIcon} from "@heroicons/react/24/outline";
import {Switch} from "@headlessui/react";

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  price: Price;
  index: number;
  removePriceHandler: (index: number) => void;
}

const PriceEdit = ({price, removePriceHandler, index}: Props): JSX.Element => {
  const [enabled, setEnabled] = useState<boolean>(price.recurrence);
  const [priceValue, setPriceValue] = useState<number>(price.amount);
  const [priceIntervalCount, setPriceIntervalCount] = useState<number | undefined>(price.interval_count ?? undefined);
  const [priceInterval, setPriceInterval] = useState<string | undefined>(price.interval ?? undefined);

  const changePriceHandler = (e: React.ChangeEvent<HTMLInputElement>, price: Price): void => {
    setPriceValue(+e.target.value);
    price.amount = +e.target.value;
  };

  const changeRecurrenceHandler = (e: boolean | ((prevState: boolean) => boolean), price: Price): void => {
    setEnabled(e);
    if (typeof e === "boolean") {
      price.recurrence = e;
    }
    if (!price.recurrence) {
      price.interval = null;
      price.interval_count = null;
    }
  };
  const changeIntervalCountHandler = (e: React.ChangeEvent<HTMLInputElement>, price: Price): void => {
    setPriceIntervalCount(+e.target.value);
    price.interval_count = +e.target.value;
  };
  const changeIntervalHandler = (e: React.ChangeEvent<HTMLSelectElement>, price: Price): void => {
    setPriceInterval(e.target.value);
    price.interval = e.target.value as PriceInterval;
  };

  return (
    <div
      data-cy={`price-${index}`}
      className="relative rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-3 sm:pt-3  hover:outline hover:outline-offset-2 hover:outline-1 hover:outline-indigo-300 ">
      <XCircleIcon
        className="w-6 h-6 drop-shadow cursor-pointer absolute -top-2 -right-2 border-2 border-white bg-white rounded-full"
        onClick={() => removePriceHandler(index)}/>

      <dd className="ml-0 flex items-baseline pb-3 sm:pb-3">
        <div>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input type="text" name="price_amount" id="price" value={priceValue} data-cy={`price-amount`}
                   onChange={(e) => changePriceHandler(e, price)}
                   className="block w-full  rounded-md border-gray-300  pr-12 focus:border-indigo-500 focus:ring-indigo-500 text-2xl font-semibold text-gray-900"
                   placeholder="0.00" aria-describedby="price-currency"/>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
            USD
              </span>
            </div>
          </div>

          <div className="py-5">
            <Switch.Group as="div" className="flex items-center">
              <Switch checked={enabled} onChange={() => changeRecurrenceHandler(!enabled, price)}
                      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span className="sr-only">Use setting</span>
                <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white"/>
                <span aria-hidden="true" className={classNames(
                  enabled ? "bg-indigo-600" : "bg-gray-200",
                  "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                )}/>
                <span aria-hidden="true" className={classNames(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                )}/>
              </Switch>
              <Switch.Label as="span" className="ml-3">
                <span className="text-sm  text-gray-900">Recurring Payment</span>

              </Switch.Label>
            </Switch.Group>
          </div>

          {enabled && <div>


            <div className="relative mt-1 rounded-md shadow-sm w-44">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">every</span>
              </div>
              <input type="number" value={priceIntervalCount} onChange={(e) => {
                changeIntervalCountHandler(e, price);
              }} id="price_interval_count" name="price_interval_count"
                     className="block w-full rounded-md border-gray-300 pl-16  pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <select id="currency" name="currency"
                        className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-0 focus:ring-0 sm:text-sm"
                        value={priceInterval} onChange={(e) => {
                  changeIntervalHandler(e, price);
                }}>
                  <option value={PriceInterval.DAY}>days</option>
                  <option value={PriceInterval.WEEK}>weeks</option>
                  <option value={PriceInterval.MONTH}>months</option>
                  <option value={PriceInterval.YEAR}>years</option>
                </select>
              </div>
            </div>
          </div>}

        </div>

      </dd>
    </div>
  );
};
export default PriceEdit;