import axios from "axios";
import {Product} from "../../dataModel";
import {apiDomain} from "../../../../config";


export const saveImageAPI = async (image: File | Blob | string | null): Promise<Product | undefined> => {
  if (image == null) {
    return undefined;
  }
  const formData = new FormData();
  formData.append("image", image);


  try {
    return await axios.post(`${apiDomain}/products/add_image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  } catch (error) {
    console.log(error);
  }


};

export const newProductApi = async (product: Product): Promise<Product | undefined> => {
  const authUrl = `${apiDomain}/products`;
  return await axios.post(authUrl, {
    name: product.name,
    description: product.description,
    prices: product.prices,
    image_id: product.imageUID ?? null,
    active: product.active
  });

};

export const updateProductApi = async (product: Product): Promise<Product | undefined> => {
  const authUrl = `${apiDomain}/products`;
  return await axios.put(authUrl, {
    id: product.id,
    name: product.name,
    description: product.description,
    prices: product.prices,
    image_id: product.imageUID ?? null,
    active: product.active
  });

};

export const deleteProductApi = async (id: string): Promise<Product | undefined> => {
  const authUrl = `${apiDomain}/products/${id}`;
  return await axios.delete(authUrl);

};