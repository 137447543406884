import React, {useEffect, useState} from "react";
import {ProductShowMachine} from "./machine/machine";
import {useMachine} from "@xstate/react";
import {BackTo} from "../BackTo";
import {ClipboardIcon, PencilSquareIcon} from "@heroicons/react/24/outline";
// import {ShortInfo} from "./partials/ShortInfo";
import {useNavigate} from "react-router-dom";
import {Product} from "../dataModel";
import {ShowImage} from "../common/ShowImage";
import PriceSmall from "../products_list/partials/PriceSmall";
import moment from "moment";
import {ActiveInfo} from "../common/ActiveInfo";
import Loader from "../../../common/loading";

interface Props {
  uid: string;
}


export const ShowProduct = ({uid}: Props): JSX.Element => {
  const [product, setProduct] = useState<Product | null>(null);
  const [current, send] = useMachine(ProductShowMachine);
  const navigate = useNavigate();

  useEffect(() => {
    send("GET_PRODUCT", {id: uid});
  }, [uid, send]);
  const productReady = current.matches("productReady");
  useEffect(() => {

    if (productReady) {
      setProduct(current.context);
    }
  }, [current.context, productReady]);

  if (current.matches("gettingProduct")) {
    return <div><Loader/></div>;
  }

  return (
    <div>
      {current.matches("productReady") && (product != null) && (
        <>
          <div className="flex justify-between items-end mb-3">
            <BackTo/>
            <div className="flex flex-col md:flex-row lg:justify-between items-center mb-3">
              <div className="flex items-center md:mr-4 mb-3 md:mb-0">
                <div className="text-xs md:text-sm text-gray-500"
                     data-cy="product-stripe-id">{product.stripe_product_id}</div>
                <div>
                  <ClipboardIcon className="h-4 w-4 text-gray-500 ml-1"/>
                </div>
              </div>
              <button type="button" data-cy="edit-product-button"
                      onClick={() => navigate(`/product/${uid}/edit`)}
                      className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <PencilSquareIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>
                Edit Product
              </button>


            </div>
          </div>

          <div className="divide-y overflow-hidden rounded-lg bg-white shadow p-5">
            <div className="md:flex">

              <div className="mr-4 relative">
                <div className="absolute top-3 left-3">
                  <ActiveInfo active={product.active}/>
                </div>
                <div className="rounded-xl overflow-hidden">
                  <ShowImage imageUrl={product.image_url} alt={product.name}/>
                </div>
              </div>
              <div className="pt-3">
                <h1 className="text-2xl" data-cy="product-name-header">{product.name}</h1>
                <div>
                  <div className="text-sm text-gray-600" data-cy="product-description">{product.description}</div>
                  <div className="mt-6">

                    <div className="mt-0 flex md:space-x-4 pt-0 divide-y flex-col md:flex-row md:divide-x md:divide-y-0"
                         data-cy="product-price">
                      {product.prices.map((price) => (
                        <PriceSmall key={price.stripe_price_id} price={price}/>
                      ))}
                    </div>
                  </div>
                  {product.created_at !== null && product.created_at !== undefined ? (
                    <div>
                      <div
                        className="mt-6 text-xs text-gray-500">Created: {moment(product.created_at).format("MMMM Do, YYYY [at] h:mm A")}</div>
                    </div>
                  ) : null}

                </div>
              </div>

            </div>

            {/* <div className="flex space-x-4 pt-3 divide-x"> */}
            {/*  <ShortInfo label="Created" data="Oct 22, 2022"/> */}
            {/*  <ShortInfo label="Updated" data="Oct 22, 2022"/> */}
            {/*  <ShortInfo label="MRR" data="20 USD"/> */}
            {/* </div> */}

          </div>

          {/* <h2 className="mt-6 text-xl mb-2 ml-1">Events</h2> */}
          {/* <div className="overflow-hidden rounded-lg bg-white shadow p-5"> */}
          {/*  <EventsFeed/> */}
          {/*  <div className="mt-5 text-xs"> */}
          {/*    <a className="text-indigo-600">View more events <ArrowSmallRightIcon */}
          {/*      className="h-4 w-4 text-indigo-600 inline-block mr-1" aria-hidden="true"/></a> */}
          {/*  </div> */}
          {/* </div> */}

        </>
      )
      }
    </div>
  );
};



