import axios from "axios";
import {Product} from "../../dataModel";
import {apiDomain} from "../../../../config";


export const getProductsAPI = async (): Promise<Product | undefined> => {
  try {
    return await axios.get(`${apiDomain}/products`);
  } catch (e) {
    console.log(e);
  }
};