import React, {createContext, FC, ReactNode} from "react";
import {useInterpret} from "@xstate/react";
import authMachine from "./authMachine";
import {userInfoMachine} from "../features/onboarding/userInfoMachine";
import {ActorRefFrom} from "xstate";

interface GlobalStateContextType {
  authService: ActorRefFrom<typeof authMachine>;
  userInfoService: ActorRefFrom<typeof userInfoMachine>;
}

export const GlobalStateContext = createContext({} as GlobalStateContextType);

interface Props {
  children: ReactNode;
}

export const GlobalStateProvider: FC<Props> = ({children}) => {
  const authService = useInterpret(authMachine);
  const userInfoService = useInterpret(userInfoMachine);
  return (
    <GlobalStateContext.Provider value={{authService, userInfoService}}>
      {children}
    </GlobalStateContext.Provider>
  );
};
