import React, {ReactElement, useEffect, useState} from "react";
import {ProductMachine} from "./machine/machine";
import {useMachine} from "@xstate/react";
import {SubmitHandler, useForm} from "react-hook-form";
import {NewGarminApp, StrategyTemplate} from "../dataModel";
import {Transition} from "@headlessui/react";
import {PlusIcon} from "@heroicons/react/24/outline";
import SaveBtn from "./partials/saveBtn";
import AppBasicInput from "./partials/appBasicInput";
import StrategyChooser from "./partials/strategyChooser";
import PriceEdit from "./partials/PriceEdit";
import {Upload} from "./partials/Upload";
import {Navigate} from "react-router-dom";
import {DescriptionInput} from "./partials/DescriptionInput";
import Switcher from "./partials/switcher";
import Confirm from "../common/confirm";
import Loader from "../../../common/loading";

interface Props {
  uid?: string;
}

const NewProductForm = ({uid}: Props): ReactElement => {
  const [current, send] = useMachine(ProductMachine);
  const [productFile, setProductFile] = useState<File | null>(null);
  const [hideStrategy] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
  } = useForm<NewGarminApp>();

  useEffect(() => {
    if (uid != null) {
      send("GET_PRODUCT", {id: uid});
    }
  }, [uid, send]);

  useEffect(() => {
    setValue("name", current.context.name);
  }, [current.context.name, setValue]);

  useEffect(() => {
    setValue("description", current.context.description);
  }, [current.context.description, setValue]);

  useEffect(() => {
    setIsActive(current.context.active);
  }, [current.context.active]);


  const onSubmit: SubmitHandler<NewGarminApp> = (data): void => {
    send("SAVE", {name: data.name, description: data.description, active: isActive});
  };
  // console.log(current.value);
  const assignStrategyTemplate = (strategyTemplate: StrategyTemplate): void => {
    console.log("assignStrategyTemplate", strategyTemplate.name, "");
    // eslint-disable-next-line no-debugger
    send("ASSIGN_STRATEGY_TEMPLATE", {strategyTemplate});
  };
  const addPriceHandler = (): void => {
    send("ADD_PRICE");
  };
  const removePriceHandler = (index: number): void => {
    send("REMOVE_PRICE", {price_index: index});
  };
  const handleUploadFile = (file: File): void => {
    setProductFile(file);
    console.log({file});
    send("ADD_FILE", {newFile: file});
  };
  if (current.matches("gettingProduct") || current.matches("savingProduct") || current.matches("deletingProduct")) {
    return <div><Loader/></div>;
  }

  if (current.matches("savedProduct")) {
    return <Navigate replace to={"/product/" + current.context.id}/>;
  }
  if (current.matches("deletedProduct")) {
    return <Navigate replace to="/products"/>;
  }
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="px-4 py-5 sm:p-6">
          <div className="flex gap-4">
            <div className="flex-auto">
              <Switcher isActive={isActive} onSwitchChange={setIsActive}/>
              <AppBasicInput register={register("name")}/>
              <DescriptionInput register={register("description")}/>
              <Upload handleUploadFile={handleUploadFile} file={productFile}
                      imageUrl={current.context.image_url}/>
            </div>

          </div>


          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 mt-3">
            <Transition show={!hideStrategy} enter="transition ease-out duration-500"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
              {hideStrategy ? (<></>) : (
                <>
                  <h3 className="block text-md font-medium text-gray-700 mt-8 mb-2">Choose
                    your price strategy
                  </h3>

                  <div className="transition-all">
                    <StrategyChooser assignStrategyTemplate={assignStrategyTemplate}/>
                  </div>
                  {current.context.error !== "" &&
                    <div
                      className="bg-red-100 text-red-500 text-sm py-1 px-2 rounded font-bold mt-1">{current.context.error}</div>
                  }
                </>
              )}
            </Transition>
            <div>
              <Transition show={current.context.prices.length > 0}
                          enter="transition ease-out duration-500"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                <div>
                  <h3 className="block text-md font-medium text-gray-700 mt-6 mb-2">Customize
                    prices</h3>
                  <div className="bg-gray-100 rounded-lg p-5">
                    {current.context.prices.length > 0 && (
                      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4  gap-4">
                        {current.context.prices.map((price, index) => (
                          <PriceEdit key={Math.random()} index={index}
                                     price={price}
                                     removePriceHandler={removePriceHandler}/>
                        ))}
                      </div>
                    )}
                    <div className="text-right mt-4">
                      <button onClick={addPriceHandler} type="button"
                              className="inline-flex items-center rounded-full border border-transparent bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <PlusIcon className="h-6 w-6" aria-hidden="true"/>
                      </button>
                    </div>
                  </div>

                </div>
              </Transition>

            </div>


          </div>

          <div className="pt-5 flex justify-between">
            {current.context.id !== "" ? (
              <div>
                <Confirm
                  open={openConfirm} setOpen={setOpenConfirm}
                  title="Delete product"
                  description="Are you sure you want to delete this product? If someone has purchased a subscription, they will have lifetime access to all features. Additionally, if someone attempts to purchase this application, unfortunately, it will not be possible."
                  onConfirm={() => send("DELETE_PRODUCT", {id: current.context.id})}
                  confirmBtnText="Delete"

                />
                <div
                  onClick={() => setOpenConfirm(true)}
                  className="cursor-pointer ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                  Delete product
                </div>
              </div>
            ) : (<div></div>)}
            <div>
              {current.matches("savingImage") ? <div>wait for image...</div> : <SaveBtn/>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewProductForm;