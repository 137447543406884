import React from "react";
import {UseFormRegisterReturn} from "react-hook-form";

const AppBasicInput = (props: { register: UseFormRegisterReturn<string> }):JSX.Element => <>
  <label htmlFor="email" className="block text-md font-medium text-gray-700">
    Your Product name
  </label>
  <div className="mt-1">
    <input
      {...props.register} type="text" required id="name" data-testid="input-name"
      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      aria-describedby="product-description"
      data-cy="product-name-input"

    />
  </div>
  <p className="mt-2 text-sm text-gray-500" id="email-description">
    This information will be displayed publicly so be careful what you share. </p>
</>;

export default AppBasicInput;