import React from "react";
import {Price as PriceModel, PriceInterval} from "../../dataModel";


interface Props {
  price: PriceModel;
}

const PriceRecurrence = ({price}: Props): JSX.Element => {

  if (price.interval == null) {
    return <span className="text-xs text-gray-500">One time</span>;
  }
  if (price.recurrence) {
    if (price.interval_count == null) {
      return <span className="text-xs text-red-700">Error</span>;
    }
    switch (price.interval) {
    case PriceInterval.DAY:
      return <span
        className="text-xs text-gray-500">{price.interval_count === 1 ? "1 day" : `${price.interval_count} days`} </span>;
    case PriceInterval.MONTH
    :
      return <span
        className="text-xs text-gray-500">{price.interval_count === 1 ? "1 month" : `${price.interval_count} months`} </span>;
    case PriceInterval.WEEK:
      return <span
        className="text-xs text-gray-500">{price.interval_count === 1 ? "1 week" : `${price.interval_count} weeks`}</span>;
    case PriceInterval.YEAR:
      return <span
        className="text-xs text-gray-500">{price.interval_count === 1 ? "1 year" : `${price.interval_count} years`}</span>;
    default:
      return <span className="text-xs text-gray-500"></span>;
    }
  } else {
    return <span className="text-xs text-gray-500">Lifetime</span>;
  }
};
const Price = ({price}: Props): JSX.Element => {
  return (
    <div className="md:pl-3 first:pl-0 min-w-min">
      <dt>

        <p
          className="ml-0 truncate text-sm font-small text-gray-500 pt-1">
          <PriceRecurrence price={price}/></p>
      </dt>
      <dd className="ml-0 flex items-baseline pb-1">
        <p className="text-sm font-semibold text-gray-900 whitespace-nowrap">{price.amount} USD</p>
      </dd>
    </div>
  );
};
export default Price;