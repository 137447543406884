/* This example requires Tailwind CSS v2.0+ */
import React, {useEffect, useState} from "react";
import {useMachine} from "@xstate/react";

import {ProductsListMachine} from "./machine/machine";
import PriceSmall from "./partials/PriceSmall";
import {Link} from "react-router-dom";
import {ProductFetched} from "../dataModel";
import {ShowImage} from "../common/ShowImage";

import {ActiveInfo} from "../common/ActiveInfo";
import Loader from "../../../common/loading";


const ProductsList = (): JSX.Element => {
  const [products, setProducts] = useState<ProductFetched[]>([]);

  const [current, send] = useMachine(ProductsListMachine);
  useEffect(() => {
    send("GET_PRODUCTS");
  }, [send]);
  const productReady = current.matches("productsReady")
  useEffect(() => {
    if (productReady) {
      setProducts(current.context.products);
    }

  }, [current.context.products,productReady]);


  if (!productReady) {
    return <Loader/>;
  }

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">

      <div className="flex flex-wrap">
        {products.map((product) => (
          <div className="w-full md:w-1/2 lg:w-1/3 p-4"  key={product.id} data-cy="product">
            <Link to={`/product/${product.id}`} className="block hover:bg-gray-50">
              <div className="bg-white border border-gray-200 rounded-lg overflow-hidden relative">
                <div className="border-b">
                  <ShowImage imageUrl={product.image_url} alt={product.name}/>
                </div>
                <div className="p-4">
                  <h2 className="text-xl font-bold mb-2">{product.name}</h2>
                  <div className="h-[80px]">
                    <p className="text-gray-700 text-base mb-2 line-clamp-2">{product.description}</p>
                  </div>
                  <div className="absolute top-0 right-0 mt-2 mr-2">
                    <ActiveInfo active={product.active}/>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="relative overflow-hidden mt-0 h-12 w-full">
                      <div className="mt-0 flex flex-nowrap space-x-4 pt-0 divide-x fade-right"
                           data-cy="product-prices">
                        {product.prices.map((price) => (
                          <PriceSmall key={price.stripe_price_id} price={price}/>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </Link>
          </div>
        ))}
    </div>
    </div>
  );
};

export default ProductsList;