import React from "react";

interface Props {
    imageUrl: string | undefined;
    alt: string;
}

export const ShowImage = ({ imageUrl, alt }:Props):JSX.Element => {
  return (
    <>
      {
        (imageUrl != null) ?
          (<img className="w-full object-cover h-48 " alt={alt} src={imageUrl}/>) :
          (<div className="w-full object-cover h-48  bg-gray-100"></div>)
      }
    </>
  );
};

