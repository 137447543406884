import {LoginData, LoginToken, Token, UserKeyCloakSchema} from "../features/singIn/dataModel";
import axios from "axios";
import {apiDomain} from "../config";


export const authAPI = async (credentials: LoginData): Promise<LoginToken | undefined> => {
  const authUrl = `${apiDomain}/auth/login`;
  return await axios.post(authUrl, {
    username: credentials.email,
    password: credentials.password,
  });
};
export const refreshTokenAPI = async (): Promise<LoginToken | undefined> => {
  const authUrl = `${apiDomain}/auth/refresh_token`;
  const token = localStorage.getItem("refresh_token");
  if (token != null) {
    return await axios.get(authUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
export const checkTokenAPI = async (token: Token): Promise<UserKeyCloakSchema | undefined> => {
  const authUrl = `${apiDomain}/auth/user_info`;
  if (token?.access_token != null) {
    return await axios.get(authUrl, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      });
  }
};




