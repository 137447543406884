import React from "react";
import DashboardLayout from "../common/components/DashboardLayout";
import NewProductForm from "../features/products_management/add_product/newProductForm";
import {useParams} from "react-router-dom";


const NewProductPage = ():JSX.Element => {
  const params = useParams();
  return (
    <DashboardLayout title={(params.id != null) ? "Edit product" : "New Product"} headerDesc="Edit your product to offer various subscription plans or provide lifetime access for ultimate convenience.">
      <NewProductForm uid={params.id}/>
    </DashboardLayout>
  );
};

export default NewProductPage;
