import React from "react";
import {UseFormRegisterReturn} from "react-hook-form";

export const DescriptionInput =  (props: { register: UseFormRegisterReturn<string> }):JSX.Element => {

  return (
    <div className="mt-8 mb-8">
      <label htmlFor="comment" className="block text-md font-medium text-gray-700">
        Description

      </label>
      <div className="mt-1">
        <textarea rows={2} id="comment"
                  required
                  data-cy={"product-description-input"}
          {...props.register}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <p className="mt-2 text-sm text-gray-500" id="email-description">
        Product descriptions appear at checkout. </p>
    </div>
  );
};

