import React, {ReactElement} from "react";

const Loader = (): ReactElement => {
  // You can add any UI inside Loading, including a Skeleton.
  return (
    <div className="flex justify-center items-center h-screen text-black">

      <div className="loader"></div>
    </div>
  );
};

export default Loader;