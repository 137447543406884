import {createModel} from "xstate/lib/model";
import {ProductFetched} from "../../dataModel";
import {getProductsAPI} from "./apiCall";


export const productListModel = createModel({
  products: [] as ProductFetched[],
}, {
  events: {
    GET_PRODUCTS: () => ({}),
  }
});

export const ProductsListMachine = productListModel.createMachine({
  id: "productList",
  initial: "init",
  context: productListModel.initialContext,
  states: {
    init: {
      on: {
        GET_PRODUCTS: {
          target: "gettingProducts",
        }
      }
    },
    gettingProducts: {
      invoke: {
        src: "getProducts",
        onDone: {
          target: "productsReady",
          actions: "assignProducts",
        }
      }
    },
    productsReady: {
      type: "final",
    }

  },
}, {
  actions: {
    assignProducts: productListModel.assign({
      products: (context, event) => (event as any).data.data,
    }),
  },
  services: {
    getProducts: async () => {
      return await getProductsAPI();
    }
  }


});

