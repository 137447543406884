import {createMachine, DoneInvokeEvent} from "xstate";
import {getStripeAccountLinkAPI, getUserInfoAPI} from "./apiCall";


type Event = DoneInvokeEvent<any>;


interface Context {
  user_id: string,
  status: string,
  error: string,
  strip_account_link: string,
}

const initialContext: Context = {
  user_id: "",
  status: "",
  error: "",
  strip_account_link: "",
};

export const userInfoMachine = createMachine<Context, Event>({
  predictableActionArguments: true,
  id: "userInfo",
  initial: "idle",
  context: initialContext,
  states: {
    idle: {
      on: {
        FETCH_USER_INFO: {
          target: "fetching",
        },
      }
    },
    fetching_stripe_account_link: {
      invoke: {
        id: "fetching_stripe_account_link",
        src: "getStripeAccountLink",
        onDone: {
          target: "succeeded",
          actions: (context, event) => {
            console.log("event.data", event.data.data);
            context.strip_account_link = event.data.data.stripe_account_link;
          }
        },
        onError: {
          target: "idle",
          actions: ["saveErrors"]
        }
      }
    },
    fetching: {
      invoke: {
        id: "fetchUserInfo",
        src: "fetchUserInfo",
        onDone: {
          target: "succeeded",
          actions: ["saveUserInfo"]
        },
        onError: {
          target: "idle",
          actions: ["saveErrors"]
        }
      }
    },
    notConnected: {},
    succeeded: {
      on: {
        GET_STRIPE_ACCOUNT_LINK: {
          target: "fetching_stripe_account_link",
        }
      }
    }
  }
}, {
  actions: {
    saveUserInfo: (context, event) => {
      context.user_id = event.data.data.user_id;
      context.status = event.data.data.status;

    },
    saveErrors: (context, event) => {
      context.error = event.data.error;
    }
  },
  services: {
    fetchUserInfo: async () => {
      return await getUserInfoAPI();
    },
    getStripeAccountLink:async (context, event) => {
      console.log("event ---------------", event);
      if(event.type === "GET_STRIPE_ACCOUNT_LINK") {
        return await getStripeAccountLinkAPI();
      }
    }
  }
});