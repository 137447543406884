import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import {BrowserRouter as Router} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./routes";
import {GlobalStateProvider} from "./auth/GlobalStateProvider";

// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <GlobalStateProvider>
      <Router>
        <AppRoutes />
      </Router>
    </GlobalStateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
