export interface GarminApp {
  id: string;
  name: string;
}

export interface NewGarminApp {
  name: string;
  description: string;
}
export interface GarminAppState {
  status: "idle" | "pending" | "succeeded" | "failed";
  garminApps: GarminApp[];
}
export enum PriceInterval {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}


export interface StrategyTemplate  {
  id: string;
  name: string;
  description: string;
  strategy_template_prices: Price[];
}
export interface Product {
  id: string | undefined;
  name: string;
  description: string;
  active: boolean;
  created_at?: string;
  stripe_product_id?: string;
  prices: Price[];
  productImage?: undefined | string;
  imageUID?: undefined | string;
  image_url?: undefined | string;
}
export interface ProductFetched extends Product {
  id: string;
}

export interface Price {
  id?: string;
  recurrence: boolean,
  amount: number,
  interval: PriceInterval | null,
  interval_count: number | null,
  stripe_price_id?: string;
}

