import React from "react";

interface Props {
  active: boolean;
}

export const ActiveInfo = ({active}: Props): React.ReactElement => {
  return <div>
    {active ? (
      <span
        className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Active
                      </span>
    ) : (
      <span
        className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                        Inactive
                      </span>
    )}
  </div>;
};