import React, {ReactElement, useEffect, useState} from "react";
import {Switch} from "@headlessui/react";

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

interface SwitcherProps {
  isActive: boolean;
  onSwitchChange: (value: boolean) => void;
}

const Switcher = ({isActive, onSwitchChange}: SwitcherProps): ReactElement => {
  const [enabled, setEnabled] = useState(isActive);
  useEffect(() => {
    setEnabled(isActive);
  }, [isActive]);
  console.log("Switcher", enabled, isActive);
  const handleSwitchChange = (value: boolean): void => {
    setEnabled(value);
    onSwitchChange(value);
  };
  return (
    <Switch.Group as="div" className="flex items-center justify-between mb-8">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="block text-md font-medium text-gray-700" passive>
          Product availability
        </Switch.Label>

        <Switch.Description as="span" className="text-sm text-gray-500">
         Check if you want to make your product available for sale.
        </Switch.Description>
      </span>
      <Switch
        checked={enabled}
        onChange={handleSwitchChange}
        className={classNames(
          enabled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
    </Switch.Group>
  );
};
export default Switcher;