import {ArrowSmallLeftIcon} from "@heroicons/react/24/outline";
import React from "react";
import {Link} from "react-router-dom";

export const BackTo = ():JSX.Element => {
  return (
    <Link to="/products" className="flex items-center mb-3">

      <ArrowSmallLeftIcon className="h-4 w-4 text-gray-500 inline-block mr-1" aria-hidden="true"/>
      <div className="text-xs text-gray-500 uppercase">Products</div>
    </Link>
  );
};