import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";


import SuperTokens, {SuperTokensWrapper} from "supertokens-auth-react";
import ThirdPartyEmailPassword, {Github, Google} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session, {SessionAuth} from "supertokens-auth-react/recipe/session";

import App from "./App";

import Error404 from "./Pages/Error404";
import ProductsListPage from "./Pages/ProductsListPage";
import NewProductPage from "./Pages/NewProductPage";
import {ProductPage} from "./Pages/ProductPage";
import {apiDomain, appDomain} from "./config";


import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";
// import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import * as reactRouterDom from "react-router-dom";
SuperTokens.init({
  appInfo: {
    // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
    appName: "Monkey Cart",
    apiDomain,
    websiteDomain: appDomain,
    apiBasePath: "/auth",
    websiteBasePath: "/auth",

  },
  recipeList: [
    // EmailVerification.init({
    //   mode: "REQUIRED",
    // }),
    ThirdPartyEmailPassword.init({
      signInAndUpFeature: {
        providers: [
          Github.init(),
          Google.init(),

        ]
      },
      style: `[data-supertokens=superTokensBranding]{display:none;}
        [data-supertokens=container]{
          box-shadow: 0 0 0 0 rgba(0,0,0,0);
          font-family: inherit;
          border-radius: 1rem;
        }
        [data-supertokens="button"]{
          background-color: rgb(79 70 229 / var(--tw-bg-opacity));
          border: 0;
          padding:15px;
          height: auto;
        }
      `

      // style:{
      //   superTokensBranding:{
      //     display: "none"
      //   }
      // }
    }),
    Session.init()
  ]
});

const AppRoutes = (): JSX.Element => (
  <App>
    <SuperTokensWrapper>
      <Routes>
        {/* <Route path="/signin" element={<SignInPage />} /> */}
        {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [ThirdPartyEmailPasswordPreBuiltUI])}
        <Route
          path="/products"
          element={
            <SessionAuth>
              <ProductsListPage/>
            </SessionAuth>
          }
        />
        <Route
          path="/"
          element={
            <SessionAuth>
              <>
                <Navigate to={"/products"} replace={true}/>
              </>
            </SessionAuth>
          }
        />

        {/* <Route */}
        {/*  path="/products" */}
        {/*  element={ */}
        {/*    <SessionAuth> */}
        {/*      <ProductsListPage/> */}
        {/*    </SessionAuth> */}
        {/*  } */}
        {/* /> */}


        <Route
          path="/products/new"
          element={
            <SessionAuth>
              <NewProductPage/>
            </SessionAuth>
          }
        />
        <Route
          path="/product/:id"
          element={
            <SessionAuth>
              <ProductPage/>
            </SessionAuth>
          }
        />
        <Route
          path="/product/:id/edit"
          element={
            <SessionAuth>
              <NewProductPage/>
            </SessionAuth>
          }
        />

        <Route element={<Error404/>}/>
      </Routes>
    </SuperTokensWrapper>
  </App>
);
export default AppRoutes;
