import React from "react";

const Error404 = ():JSX.Element => {
  return (
    <div className="Error404Page">
      <h1>Error 404</h1>
    </div>
  );
};

export default Error404;
