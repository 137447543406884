import axios from "axios";
import {apiDomain} from "../../config";
import {UserConnectionSchema} from "../singIn/dataModel";


export const getUserInfoAPI = async (): Promise<UserConnectionSchema | undefined> => {
  return await axios.get(`${apiDomain}/stripe_connector/user_connection`);
};


export const getStripeAccountLinkAPI = async (): Promise<UserConnectionSchema | undefined> => {
  return await axios.get(`${apiDomain}/stripe_connector/stripe_account_link`);
};