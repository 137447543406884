import React, {useContext, useEffect, useState} from "react";
import {GlobalStateContext} from "../../auth/GlobalStateProvider";
import {useSelector} from "@xstate/react";


export const OnBoardingCta = ():JSX.Element => {

  const [loading, setLoading] = useState(false);
  const globalServices = useContext(GlobalStateContext);
  const userInfoError = useSelector(globalServices.userInfoService, (state) => {
    return state.context.error;
  });

  const stripeAccountLink = useSelector(globalServices.userInfoService, (state) => {
    return state.context.strip_account_link;
  });

  const connectionStatus = useSelector(globalServices.userInfoService, (state) => {
    return state.context.status;
  });

  useEffect(() => {
    globalServices.userInfoService.send({type: "FETCH_USER_INFO", data: {}});
  }, [globalServices.userInfoService]);

  useEffect(() => {
    if (stripeAccountLink !== "") {
      console.log("stripeAccountLink", stripeAccountLink);
      window.location.href = stripeAccountLink;
    }
  }, [stripeAccountLink]);

  const handleConnectWithStripe = ():void => {
    console.log("connect with stripe");
    if (loading) {
      return;
    }

    console.log("connect with stripe step 2");
    globalServices.userInfoService.send({type: "GET_STRIPE_ACCOUNT_LINK", data: {}});
    setLoading(true);
  };

  // if (globalServices.userInfoService.state.matches("idle")) {
  //   return (
  //     <LoadingSpinner colorClass="gray-500"/>
  //   );
  // }
  if (userInfoError !== "") {
    return (<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
      <strong className="font-bold">Error! </strong>
      <span className="block sm:inline">{userInfoError}</span>
    </div>);
  }
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Sell your apps with confidence</span>
          <span className="block text-xl font-normal">Connect with <strong>Stripe</strong> for secure and easy payment processing</span>
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <button onClick={() => handleConnectWithStripe()} disabled={loading}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700">
              {loading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                  </svg>
                  Processing... </>
              ) : (
                <> {connectionStatus === "partially_connected" ? "Click here to continue onboarding" : "Connect with Stripe"} </>
              )}
            </button>
          </div>
          <div className="ml-3 inline-flex">
            <a href="#"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-5 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200">
              Learn more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
