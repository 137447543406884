import React from "react";
import {FileUploader} from "react-drag-drop-files";

const fileTypes = ["JPG", "PNG", "GIF"];
interface Props  {
  imageUrl?: string | null;
  file: File | null;
  handleUploadFile?: (file: File) => void;
}

const ImagePreview = ({file, imageUrl}: Props):JSX.Element => {
  if (file != null) {
    return <img src={URL.createObjectURL(file)} alt="Preview"
      className="max-h-28 drop-shadow mx-auto rounded-md border border-b-base-100"/>;
  }
  if (imageUrl != null && imageUrl !== "") {
    return <img src={imageUrl} alt="Preview"
      className="h-28 drop-shadow mx-auto rounded-md border border-b-base-100"/>;
  }
  return (
    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
      aria-hidden="true">
      <path
        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
        strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
export const Upload = ({file, imageUrl, handleUploadFile}: Props):JSX.Element => {
  return (
    <div className="sm:col-span-6">
      <label htmlFor="cover-photo" className="block text-md font-medium text-gray-700">
        Product photo
      </label>
      <FileUploader handleChange={handleUploadFile} name="file" types={fileTypes}>
        <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
          <div className="space-y-1 text-center">
            <ImagePreview imageUrl={imageUrl} file={file}/>
            <div className="flex text-sm text-gray-600 justify-center">
              <label htmlFor="file-upload"
                className="relative cursor-pointer rounded-md font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                <span>Upload a file</span>
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
          </div>
        </div>
      </FileUploader>
    </div>

  );
};