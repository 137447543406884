import React, {FC, ReactNode} from "react";

import "./App.css";

interface Props {
  children: ReactNode;
}

const App: FC<Props> = ({children}) => {
  return (
    <div className="">
      <div className="">
        {children}
      </div>
    </div>);
};

export default App;
