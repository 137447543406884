import {assign, createMachine} from "xstate";

import {authAPI, checkTokenAPI, refreshTokenAPI} from "./apiCalls";


const authMachine = createMachine({
  predictableActionArguments: true,
  schema: {
    context: {} as { access_token: null | string, refresh_token: null | string },
    events: {} as { type: "LOGIN"; email: string; password: string; } |
      { type: "REFRESH_TOKEN"; refresh_token: string; } |
      { type: "CHECK_TOKEN" } | { type: "LOGOUT"; },
  },
  id: "auth",
  initial: "loggedOut",
  context: {access_token: null, refresh_token: null},
  states: {
    loggedOut: {
      initial: "noErrors",
      on: {
        LOGIN: [
          {
            target: "authenticating",
          }
        ],
        CHECK_TOKEN: [
          {
            target: "checkAuth"
          }
        ]
      },
      states: {
        noErrors: {},
        authFailed: {}
      }
    },
    authenticating: {
      invoke: {
        id: "authenticateUser",
        src: "authenticateUser",
        onDone: {
          target: "loggedIn",
          actions: ["saveTokens"]
        },
        onError: {
          target: "loggedOut.authFailed"
        }
      }
    },
    checkAuth: {
      invoke: {
        id: "checkAuth",
        src: "checkAuth",
        onDone: {
          target: "loggedIn",
        },
        onError: {
          target: "loggedOut"
        }
      }
    },
    refreshToken: {
      invoke: {
        id: "refreshToken",
        src: "refreshToken",
        onDone: {
          target: "loggedIn",
          actions: ["saveTokens"]
        },
        onError: {
          target: "loggedOut"
        }
      }
    },
    loggedIn: {
      on: {
        LOGOUT: "loggedOut",
        REFRESH_TOKEN: "refreshToken"
      },
      after: {
        18000: "refreshToken"
      }

    }
  }
}, {
  actions: {
    saveTokens: assign((context, event) => {
      localStorage.setItem("access_token", (event as any).data.data.access_token);
      localStorage.setItem("refresh_token", (event as any).data.data.refresh_token);
      return {
        access_token: (event as any).data.access_token,
        refresh_token: (event as any).data.refresh_token
      };
    }),


  },
  services: {
    authenticateUser: async (ctx, event) => {
      if (event.type === "LOGIN") {
        const {email, password} = event;
        return await authAPI({email, password});
      }
    },
    checkAuth: async () => {
      return await checkTokenAPI({
        access_token: localStorage.getItem("access_token"),
        refresh_token: localStorage.getItem("refresh_token")
      });
    },
    refreshToken: async () => {
      return await refreshTokenAPI();
    }
  }
});

export default authMachine;
