import {PriceInterval} from "../dataModel";

export const strategyTemplates = [

  {
    "id": "659290a3-1416-4ebf-b224-a98e2c49de00",
    "name": "Simple",
    "description": "Only one price for lifetime access",
    "strategy_template_prices": [
      {
        "recurrence": false,
        "amount": 5,
        "interval_count": null,
        "interval": null
      }
    ]
  },
  {
    "id": "71551096-d9a9-4b6a-b2ee-e973f95da8b9",
    "name": "Monthly",
    "description": "One recurring price monthly charged",
    "strategy_template_prices": [
      {
        "recurrence": true,
        "amount": 5,
        "interval": PriceInterval.MONTH,
        "interval_count": 1
      }
    ]
  },
  {
    "id": "8e8231d0-779a-42d2-b3e5-27573ff32446",
    "name": "Complex Recurring",
    "description": "Three recurring prices - monthly, quarterly, yearly",
    "strategy_template_prices": [
      {
        "recurrence": true,
        "amount": 5,
        "interval": PriceInterval.MONTH,
        "interval_count": 1
      },
      {
        "recurrence": true,
        "amount": 10,
        "interval": PriceInterval.MONTH,
        "interval_count": 3
      },
      {
        "recurrence": true,
        "amount": 30,
        "interval": PriceInterval.MONTH,
        "interval_count": 12
      }
    ]
  },
  {
    "id": "5cbc2e00-39d5-4ebe-a9fc-5abfc929fc3b",
    "name": "Recurring with Lifetime",
    "description": "Three recurring prices plus a lifetime price",
    "strategy_template_prices": [
      {
        "recurrence": true,
        "amount": 5,
        "interval": PriceInterval.MONTH,
        "interval_count": 1
      },
      {
        "recurrence": true,
        "amount": 10,
        "interval":  PriceInterval.MONTH,
        "interval_count": 3
      },
      {
        "recurrence": true,
        "amount": 30,
        "interval":  PriceInterval.MONTH,
        "interval_count": 12
      },

      {
        "recurrence": false,
        "amount": 50,
        "interval": null,
        "interval_count": null
      },
    ]
  },
];