import {createModel} from "xstate/lib/model";
import {getProductAPI} from "./apiCall";

// import {newProductApi, saveImageAPI} from "./apiCall";


export const productModel = createModel({
  id: "",
  name: "",
  productImage: "",
  image_url: "",
  prices: [],
  stripe_product_id: "",
  description: "",
  active: true,
  created_at: "",
}, {
  events: {
    GET_PRODUCT: (id: string) => ({id}),
  }
});

export const ProductShowMachine = productModel.createMachine({
  id: "productShow",
  initial: "initProduct",
  context: productModel.initialContext,
  states: {
    initProduct: {
      on: {
        GET_PRODUCT: {
          target: "gettingProduct",
        }
      }
    },
    gettingProduct: {
      invoke: {
        src: "getProduct",
        onDone: {
          target: "productReady",
          actions: "assignProduct",
        }
      }
    },
    productReady: {
      type: "final"
    }
  },
}, {
  actions: {
    assignProduct: productModel.assign({
      name: (context, event) => (event as any).data.data.name,
      image_url: (context, event) => (event as any).data.data.image_url,
      prices: (context, event) => (event as any).data.data.prices,
      description: (context, event) => (event as any).data.data.description,
      productImage: (context, event) => (event as any).data.data.productImage,
      stripe_product_id: (context, event) => (event as any).data.data.stripe_product_id,
      active: (context, event) => (event as any).data.data.active,
      created_at: (context, event) => (event as any).data.data.created_at,
    }),
  },
  services: {
    getProduct: async (ctx, event) => {
      if(event.type === "GET_PRODUCT") {
        return await getProductAPI(event.id);
      }
    }
  }
});