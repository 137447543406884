import React from "react";
import {useParams} from "react-router-dom";
import DashboardLayout from "../common/components/DashboardLayout";
import {ShowProduct} from "../features/products_management/show_product/ShowProduct";

export const ProductPage = ():JSX.Element => {
  const params = useParams<{id: string}>();
  if(params.id === undefined) {
    throw new Error("Product id is not defined");
  }
  return (
    <DashboardLayout>
      <ShowProduct uid={params.id} />
    </DashboardLayout>
  );
};