import React, {useContext} from "react";
import ProductsList from "../features/products_management/products_list/ProductsList";
import DashboardLayout from "../common/components/DashboardLayout";
import {Link} from "react-router-dom";
import {OnBoardingCta} from "../features/onboarding/OnBoarding";
import {useSelector} from "@xstate/react";
import {GlobalStateContext} from "../auth/GlobalStateProvider";

const ProductsListPage = (): JSX.Element => {
  const globalServices = useContext(GlobalStateContext);
  const connectionStatus = useSelector(globalServices.userInfoService, (state) => {
    return state.context.status;
  });


  return (
    <DashboardLayout title={connectionStatus === "connected" ? "My products" : ""} headerDesc="Below is the list of products that you have made available for sale. To view more details and edit them, please
                click on the product.">
      {connectionStatus === "connected" ?
        (<div>

          <div className="flex justify-end">
            <Link to="/products/new" className="btn mb-4"
                                                         data-cy="add-new-product-button">add new product</Link>
          </div>
          <ProductsList/>
        </div>)
        :
        (<>
          <OnBoardingCta/>
        </>)
      }
    </DashboardLayout>);
};

export default ProductsListPage;