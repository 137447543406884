import React from "react";
import {strategyTemplates} from "../strategy_templates_seed";

import {Square3Stack3DIcon,} from "@heroicons/react/24/outline";
import {StrategyTemplate} from "../../dataModel";

interface Props {
  assignStrategyTemplate: (strategy: StrategyTemplate) => void;
}


const StrategyChooser = ({assignStrategyTemplate}:Props):JSX.Element => {
  const handleSelection = (e: React.MouseEvent, strategy: StrategyTemplate):void => {
    console.log(e.target);
    assignStrategyTemplate(strategy);
  };
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      {strategyTemplates.map((strategy,index) => (
        <div
          key={strategy.id}
          className="min-h-max relative flex items-top  space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
          data-cy={`strategy-chooser-${index}`}
        >
          <div className="flex-shrink-0">
            <Square3Stack3DIcon className="w-8"/>
          </div>
          <div className="min-w-0 flex-1">
            <a className="focus: outline-none" onClick={(e)=>handleSelection(e, strategy)} tabIndex={0}>
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-sm font-medium text-gray-900">{strategy.name}</p>
              <p className="text-xs text-gray-500">{strategy.description}</p>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StrategyChooser;