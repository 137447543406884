import {createModel} from "xstate/lib/model";
import {Price, PriceInterval, StrategyTemplate} from "../../dataModel";
import {deleteProductApi, newProductApi, saveImageAPI, updateProductApi} from "./apiCall";
import {getProductAPI} from "../../show_product/machine/apiCall";


export const productModel = createModel({
    id: "",
    name: "",
    description: "",
    productFileUID: "", // image id
    productFile: null as File | null,
    image_url: "",
    prices: [] as Price[],
    assignedStrategyTemplate: false,
    active: true,
    created_at: null,
    error: "",
  },
  {
    events: {
      ASSIGN_STRATEGY_TEMPLATE: (strategyTemplate: StrategyTemplate) => ({strategyTemplate}),
      ADD_PRICE: () => ({}),
      REMOVE_PRICE: (priceIndex: number) => ({price_index: priceIndex}),
      CHANGE_PRICE: (priceIndex: number, price: Price) => ({price_index: priceIndex, price}),
      SAVE: (name: string, description: string, active: boolean) => ({name, description, active}),
      ADD_FILE: (newFile: File) => ({newFile}),
      GET_PRODUCT: (id: string) => ({id}),
      DELETE_PRODUCT: (id: string) => ({id}),
    }
  }
);


export const ProductMachine = productModel.createMachine(
  {
    id: "product",
    predictableActionArguments: true,
    initial: "initProduct",
    context: productModel.initialContext,
    states: {
      initProduct: {
        on: {
          GET_PRODUCT: {
            target: "gettingProduct",
          },
          ADD_FILE: {
            target: "savingImage",

            actions: productModel.assign({
              productFile: (context, event) => event.newFile,
            })
          },
          REMOVE_PRICE: {
            actions: productModel.assign({
              prices: (context, event) => {
                const newPrices = [...context.prices];
                console.log("event.price_index", event.price_index);
                newPrices.splice(event.price_index, 1);
                return newPrices;
              }
            })
          },
          ADD_PRICE: {
            actions: productModel.assign({

              prices: (context) => {
                context.error = "";
                return [...context.prices, {
                  recurrence: false,
                  amount: 0,
                  interval: PriceInterval.MONTH,
                  interval_count: 1,
                }];
              }
            })
          },
          ASSIGN_STRATEGY_TEMPLATE: {
            actions: productModel.assign({
              // eslint-disable-next-line no-debugger

              prices: (context, event) => {
                context.error = "";
                // eslint-disable-next-line no-debugger


                return event.strategyTemplate.strategy_template_prices;
              },
              assignedStrategyTemplate: true,
            })

          },
          DELETE_PRODUCT: {
            target: "deletingProduct",

          },
          SAVE: [
            {
              target: "savingProduct",
              actions: productModel.assign({
                name: (context, event) => event.name,
                description: (context, event) => event.description,
                active: (context, event) => event.active,
              })
            },
          ],
        },
      },
      savingImage: {
        invoke: {

          id: "savingImage",
          src: "savedImageFn",
          onDone: {
            target: "initProduct",
            actions: "assignImage"
          },
          onError: {
            target: "initProduct",

          }
        }

      },
      deletingProduct: {
        invoke: {
          src: "deleteProduct",
          onDone: {
            target: "deletedProduct",
          },
          onError: {
            target: "initProduct",
          },
        }
      },
      gettingProduct: {
        invoke: {
          src: "getProduct",
          onDone: {
            target: "initProduct",
            actions: "fillProduct",
          }
        }
      },
      savingProduct: {
        invoke: {
          id: "savingProduct",
          src: "savedProductFn",
          onDone: {
            target: "savedProduct",
            actions: "assignProduct",
          },
          onError: {
            target: "initProduct",
          },
        },
      },
      savedImage: {},
      savedProduct: {
        type: "final",
      },
      deletedProduct: {
        type: "final",
      }


    },
  },
  {

    actions: {
      assignProduct: productModel.assign({
        id: (context, event) => {
          return (event as any).data.data.id;
        }
      }),
      fillProduct: productModel.assign({
        id: (context, event) => (event as any).data.data.id,
        name: (context, event) => (event as any).data.data.name,
        description: (context, event) => (event as any).data.data.description,
        prices: (context, event) => (event as any).data.data.prices,
        image_url: (context, event) => (event as any).data.data.image_url,
        active: (context, event) => (event as any).data.data.active,
      }),
      assignImage: productModel.assign({
        productFileUID: (context, event) => {
          console.log("event.data.productFileUID", (event as any).data.data.id);
          return (event as any).data.data.id;
        }
      }),
    },
    services: {

      deleteProduct: async (ctx, event) => {
        if (event.type === "DELETE_PRODUCT") {
          return await deleteProductApi(event.id);
        } else {
          throw new Error("event type is not DELETE_PRODUCT");
        }
      },

      getProduct: async (ctx, event) => {
        if (event.type === "GET_PRODUCT") {
          return await getProductAPI(event.id);
        } else {
          throw new Error("event type is not GET_PRODUCT");
        }
      },
      savedProductFn: async ctx => {
        console.log("ctx", ctx);
        if (ctx.prices.length === 0) {
          console.log("ctx.prices", ctx.prices);
          ctx.error = "Product must have at least one price";
          throw new Error("prices is empty");

        }
        if (ctx.id !== "") {
          return await updateProductApi({
            id: ctx.id,
            name: ctx.name,
            description: ctx.description,
            prices: ctx.prices,
            imageUID: ctx.productFileUID,
            active: ctx.active
          });
        }

        return await newProductApi({
          id: undefined,
          name: ctx.name,
          description: ctx.description,
          prices: ctx.prices,
          imageUID: ctx.productFileUID,
          active: ctx.active

        });
      },
      savedImageFn: async ctx => {
        return await saveImageAPI(ctx.productFile);
      }

    }
  }
);
